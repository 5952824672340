import React from "react";
import "./location.scss";
import { SectionsComp } from "../..";
import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

function Location() {
  const { t } = useTranslation();

  const locations = [
    { id: 1, name: "اطلس فودز", position: [36.876865, 42.945999] },
    { id: 2, name: "فرع اربيل", position: [36.178246, 43.887936] },
    { id: 3, name: "موقع التحميل", position: [36.6878352, 43.0210838] },
  ];

  return (
    <div className="location">
      <SectionsComp section={t("locationT")} />
      <div className="locationContainer">
        <div className="locations">
          {/* <div className="loaction">
            <p>{t("mosul")}</p>
            <LocationOnIcon className="ico" />
          </div> */}
          <a
            href="https://maps.google.com/?q=36.178246,43.887936"
            target="_blank"
            rel="noreferrer"
          >
            <div className="loaction">
              <p>{t("erbial")}</p>
              <LocationOnIcon className="ico" />
            </div>
          </a>

          <a
            href="https://maps.google.com/?q=36.876865,42.945999"
            target="_blank"
            rel="noreferrer"
          >
            <div className="loaction">
              <p>{t("duhok")}</p>
              <LocationOnIcon className="ico" />
            </div>
          </a>

          <a
            href="https://www.google.com/maps/place/Bariya,+Nineveh+Governorate/@36.6878352,43.0210838,16z/data=!3m1!4b1!4m6!3m5!1s0x40087ebcba762b9b:0x8f325bd1649e77e1!8m2!3d36.6874816!4d43.02002!16s%2Fg%2F11g_xj1_s?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="loaction">
              <p>{t("loadLocatoinT")}</p>
              <LocationOnIcon className="ico" />
            </div>
          </a>
        </div>
        <div className="map">
          <div style={{ height: "500px", width: "100%" }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26661.99753767949!2d42.94658171545182!3d36.87471143957131!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40088d005d8b0feb%3A0xce03ed1f63b0b8d!2z2LTYsdmD2Kkg2KfYt9mE2LMg2YTZhNin2LrYsNmK2Kk!5e0!3m2!1sen!2siq!4v1732730298326!5m2!1sen!2siq"
              width="100%"
              height="500"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
