import { React, useState } from "react";
import "./gallery.scss";
import { SectionsComp } from "../..";
import { Swiper, SwiperSlide } from "swiper/react";
import coCamp from "../../../assets/images/coImages/coCamp.jpg";
import coCamp2 from "../../../assets/images/coImages/coCamp2.jpg";
import coCamp3 from "../../../assets/images/coImages/coCamp3.jpg";
import coCamp4 from "../../../assets/images/coImages/coCamp4.jpg";
import coCamp5 from "../../../assets/images/coImages/coCamp5.jpg";
import coCampVid from "../../../assets/images/coImages/coCampVid.mp4";
import coCampVid2 from "../../../assets/images/coImages/coCampVid2.mp4";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import badrea1 from "../../../assets/images/coImages/badrea1.jpeg";
import badrea2 from "../../../assets/images/coImages/badrea2.mp4";

import co1 from "../../../assets/images/moade/cos.jpeg";
import co2 from "../../../assets/images//moade/lar.jpeg";
import co3 from "../../../assets/images//moade/moad1.jpeg";
import co4 from "../../../assets/images/moade/moad2.jpeg";
import co5 from "../../../assets/images//moade/moad3.jpeg";
import co6 from "../../../assets/images/moade/moad4.jpeg";
import co7 from "../../../assets/images//moade/moad5.jpeg";
import co8 from "../../../assets/images/moade/ubabefMembers.jpeg";
import co9 from "../../../assets/images//moade/moad6.jpeg";
import co10 from "../../../assets/images/moade/moad7.jpeg";

import of1 from "../../../assets/images/office/office.jpeg";
import of2 from "../../../assets/images/office/off4.jpeg";
import of3 from "../../../assets/images/office/office3.jpeg";
import of4 from "../../../assets/images/office/office4.jpeg";
import ofVid from "../../../assets/images/office/officeVideo.mp4";

import off1 from "../../../assets/images/office/off1.jpeg";
import off2 from "../../../assets/images/office/off2.jpeg";
import off3 from "../../../assets/images/office/oof3.jpeg";
import newVideo6 from "../../../assets/images/new_video_6.MOV";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

function Gallery() {
  const { t } = useTranslation();
  const [selectedImage, setIsFullScreenImage] = useState(null);
  const [selectedtype, setSelectedtype] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const selctImage = (image, type) => {
    setIsFullScreenImage(image);
    setSelectedtype(type);
    stopAllVideos();
  };

  const stopAllVideos = () => {
    setIsPlaying(false);
  };

  return (
    <div className="gallary">
      <SectionsComp section={t("gallaryT")} />
      <div className="sectionOne">
        <h2>{t("loadLocatoin")}</h2>
        <div className="images">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            <SwiperSlide>
              <img
                src={coCamp}
                alt="slide_image"
                onClick={() => selctImage(coCamp, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={badrea1}
                alt="slide_image"
                onClick={() => selctImage(badrea1, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={coCamp2}
                alt="slide_image"
                onClick={() => selctImage(coCamp2, "img")}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="vidCon">
                <video src={coCampVid} alt="slide_image" onClick={() => selctImage(coCampVid, 'vid')}/>
                < PlayArrowIcon className='playIcon' onClick={() => selctImage(coCampVid, 'vid')}/>
              </div>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <img src={coCamp3} alt="slide_image" onClick={() => selctImage(coCamp3, 'img')} />
            </SwiperSlide> */}
            <SwiperSlide>
              <img
                src={coCamp4}
                alt="slide_image"
                onClick={() => selctImage(coCamp4, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="vidCon">
                <video
                  src={coCampVid2}
                  alt="slide_image"
                  onClick={() => selctImage(coCampVid2, "vid")}
                />
                <PlayArrowIcon
                  className="playIcon"
                  onClick={() => selctImage(coCampVid2, "vid")}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={coCamp5}
                alt="slide_image"
                onClick={() => selctImage(coCamp5, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="vidCon">
                <video
                  src={newVideo6}
                  alt="slide_image"
                  onClick={() => selctImage(newVideo6, "vid")}
                />
                <PlayArrowIcon
                  className="playIcon"
                  onClick={() => selctImage(newVideo6, "vid")}
                />
              </div>
            </SwiperSlide>

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </div>

      <div className="sectionOne">
        <h2>{t("office")}</h2>
        <div className="images">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            <SwiperSlide>
              <img
                src={off2}
                alt="slide_image"
                onClick={() => selctImage(off2, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={off3}
                alt="slide_image"
                onClick={() => selctImage(off3, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={off1}
                alt="slide_image"
                onClick={() => selctImage(off1, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={of1}
                alt="slide_image"
                onClick={() => selctImage(of1, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={of2}
                alt="slide_image"
                onClick={() => selctImage(of2, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <div className="vidCon">
                <video
                  src={ofVid}
                  alt="slide_image"
                  onClick={() => selctImage(ofVid, "vid")}
                />
                <PlayArrowIcon
                  className="playIcon"
                  onClick={() => selctImage(ofVid, "vid")}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={of3}
                alt="slide_image"
                onClick={() => selctImage(of3, "img")}
              />
            </SwiperSlide>
            {/* <SwiperSlide>
              <img src={of4} alt="slide_image" onClick={() => selctImage(of4, 'img')} />
            </SwiperSlide> */}

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </div>
      <div className="sectionOne">
        <h2>{t("othersImages")}</h2>
        <div className="images">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            <SwiperSlide>
              <img
                src={co5}
                alt="slide_image"
                onClick={() => selctImage(co5, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co9}
                alt="slide_image"
                onClick={() => selctImage(co9, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co10}
                alt="slide_image"
                onClick={() => selctImage(co10, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co8}
                alt="slide_image"
                onClick={() => selctImage(co8, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co7}
                alt="slide_image"
                onClick={() => selctImage(co7, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co6}
                alt="slide_image"
                onClick={() => selctImage(co6, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co1}
                alt="slide_image"
                onClick={() => selctImage(co1, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co2}
                alt="slide_image"
                onClick={() => selctImage(co2, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co3}
                alt="slide_image"
                onClick={() => selctImage(co3, "img")}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={co4}
                alt="slide_image"
                onClick={() => selctImage(co4, "img")}
              />
            </SwiperSlide>

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </div>
              <div className="swiper-button-next slider-arrow">
                <ion-icon name="arrow-forward-outline"></ion-icon>
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </div>
      <div
        className="full"
        style={{ display: selectedImage ? "block" : "none" }}
        onClick={() => selctImage(null)}
      >
        {selectedtype === "img" ? (
          <img
            src={selectedImage}
            alt=""
            className="fullImageImg"
            onClick={() => selctImage(null)}
          />
        ) : (
          <video
            src={selectedImage}
            autoPlay
            controls
            className="fullImageImg"
            isPlaying={isPlaying}
          />
        )}
      </div>
    </div>
  );
}

export default Gallery;
