import React from 'react';
import "./contactUs.scss";
import { SectionsComp } from "../..";
import Logo from '../../../assets/images/logo_white.png';
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useTranslation } from 'react-i18next';

function ContactUs() {
    const { t } = useTranslation();
    return (
        <div className='contactUs'>
            <SectionsComp section={t("contactUsT")} />
            <div className="container">
                <div className="content">
                    <img src={Logo} alt="atlas foods logo white" className="logo" />
                    <div className="emaill">
                        <AlternateEmailIcon className='ico' />
                        <p>info@atlasfoods-iq.com</p>
                    </div>
                    <div className="sections">
                        <div className="section">
                            <h4>DOHUK ATLASFOODS</h4>
                            <div className="call">
                                <CallIcon className='ico' />
                                <p>07701725455</p>
                                <p> - </p>
                                <p>07504507107</p>
                            </div>
                            <div className="email">
                                <AlternateEmailIcon className='ico' />
                                <p>dohuk@atlasfoods-iq.com</p>
                            </div>

                        </div>
                        <div className="section">
                            <h4>ERBIL ATLASFOODS</h4>
                            <div className="call">
                                <CallIcon className='ico' />
                                <p>07508317019</p>
                                <p> - </p>
                                <p>07701701805</p>
                            </div>
                            <div className="email">
                                <AlternateEmailIcon className='ico' />
                                <p>erbil@atlasfoods-iq.com</p>
                            </div>
                        </div>

                    </div>
                    <div className="section">
                        <h4>MOSUL ATLASFOODS</h4>
                        <div className="call">
                            <CallIcon className='ico' />
                            <p>07705866866</p>
                        </div>
                        <div className="email">
                            <AlternateEmailIcon className='ico' />
                            <p>mosul@atlasfoods-iq.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;