import { React, useState, useEffect } from 'react';
import "./navbar.scss";
import Logo from '../../assets/images/logopng.png';
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import { useLocation, useNavigate } from "react-router-dom";

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';



function Navbar() {
  let location = useLocation();
  const { t } = useTranslation();

  let navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const changeLagn = (event) => {
    event.preventDefault();
    if (location.pathname === "/en") {
      let currentLang = i18n.language;
      i18n.changeLanguage("ar"); // Change the language
      let path = `/`;
      navigate(path);
    } else {
      let currentLang = i18n.language;
      i18n.changeLanguage("en"); // Change the language
      let path = `/en`;
      navigate(path);
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 0) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const closeSide = () => {
    setOpen(false);
  }
  return (
    <div className="navbar">
      <div className="navbarContent">
        <img src={Logo} alt="Atlas Foods Logo" className='logo' />
        <div></div>
        <div></div>
        <div className="sections">

          <div className="section">
            <Link to="loaction" smooth={true} duration={1000}>
              {t("location")}
            </Link>
          </div>
          <div className="section">
            <Link to="contactUs" smooth={true} duration={1000}>
              {t("contactUs")}
            </Link>
          </div>
          <div className="section">
            <Link to="gallery" smooth={true} duration={1000}>
              {t("gallary")}
            </Link>
          </div>
          <div className="section">
            <Link to="about" smooth={true} duration={1000}>
              {t("about")}
            </Link>
          </div>
          <div className="section">
            <Link to="slide" smooth={true} duration={1000}>
              {t("home")}
            </Link>
          </div>
          <div className="lang" onClick={changeLagn}>
            <LanguageIcon className='ico' />
            <p>{location.pathname === "/en" ? "عربي" : "en"}</p>
          </div>
        </div>

      </div>
      <div className="hamburger">
        <Hamburger toggled={isOpen} toggle={setOpen} size={30} />
      </div>
      <div className="menu" style={{ display: isOpen ? "flex" : "none" }}>
        <div className="sections">
          <div className="section">
            <Link to="slide" smooth={true} duration={1000} onClick={closeSide}>
              {t("home")}
            </Link>
          </div>
          <div className="section">
            <Link to="about" smooth={true} duration={1000} onClick={closeSide}>
              {t("about")}
            </Link>
          </div>
          <div className="section">
            <Link to="gallery" smooth={true} duration={1000} onClick={closeSide}>
              {t("gallary")}
            </Link>
          </div>
          <div className="section">
            <Link to="contactUs" smooth={true} duration={1000} onClick={closeSide}>
              {t("contactUs")}
            </Link>
          </div>
          <div className="section">
            <Link to="loaction" smooth={true} duration={1000} onClick={closeSide}>
              {t("location")}
            </Link>
          </div>
          <div className="lang" onClick={changeLagn}>
          <p>{location.pathname === "/en" ? "عربي" : "en"}</p>
            <LanguageIcon className='ico' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar;