import React from 'react';
import "./about.scss";
import { SectionsComp } from "../..";
import iraqMapImage from "../../../assets/images/iraqmap.png"
import market from "../../../assets/images/market.png"
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();
  return (
    <div className='about'>
      <SectionsComp section={t("aboutT")} />
      <div className="sectionOne">
        <img src={iraqMapImage} alt="iraqMapImage" />
        <p >{t("aboutsec1")}</p>
      </div>
      <div className="sectionTow">
        
        <p>{t("aboutsec2")}</p>
        <img src={market} alt="iraqMapImage" />
      </div>
    </div>
  )
}

export default About;