import { React } from 'react';
import "./slide.scss";
import foodImage from "../../../assets/images/cover.gif";
import foodImage2 from "../../../assets/images/Good_Food_Display_-_NCI_Visuals_Online.jpg";
import { Link } from "react-scroll";
import { useTranslation } from 'react-i18next';

function Slide() {
    const { t } = useTranslation();
    const currentSlide = 0;
    // const totalSlides = 3;

    // const goToSlide = (slideIndex) => {
    //     setCurrentSlide(slideIndex);
    // };

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    //     }, 7000); // Change slide every 5000 ms (5 seconds)

    //     return () => clearInterval(timer);
    // }, []);

    return (
        <div className="slidee">
            <div className='slideshow'>
                <div className="slides-container">
                    <div className={`slide ${currentSlide === 0 ? 'active' : ''}`}>
                        <div className="slideContainer">
                            <div className="blackk"></div>
                            <div className='slideContent'>
                                <h2 >{t("companyTitle")}</h2>
                                <h3>{t("atlasCo")}<br />{t("forFood")}</h3>
                                <p>{t("compantDec")}</p>
                                <Link to="contactUs" smooth={true} duration={1000}>
                                    <button>{t("contactUs")}</button>
                                </Link>
                                
                            </div>
                            <img src={foodImage} alt="food" className='cover' />
                            <img src={foodImage2} alt="food2" className='cover2' />
                        </div>

                    </div>
                    <div className={`slide ${currentSlide === 1 ? 'active' : ''}`}>
                        <h1>Hello 2</h1>
                    </div>
                    <div className={`slide ${currentSlide === 2 ? 'active' : ''}`}>
                        <h1>Hello 3</h1>
                    </div>
                </div>

            </div>
            {/* <div className="circle-indicators">
                {[...Array(totalSlides).keys()].map(index => (
                    <span
                        key={index}
                        className={`circle ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div> */}
        </div>

    )
}

export default Slide;