import React from 'react';
import "./sectionsComp.scss";

function SectionsComp(props) {
  return (
    <div className='section'>
        <div className="lineSection"></div>
        <h3>{props.section}</h3>
    </div>
  )
}

export default SectionsComp;