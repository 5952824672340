import React, {useEffect} from 'react';
import "./home.scss";
import { Navbar, Slide, About, Gallery, ContactUs, Location } from "../../components";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Home() {
  let location = useLocation();

  const { i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if(location.pathname === "/en"){
      changeLanguage('en');
    }else{
      changeLanguage('ar');
    }
  },[1]);

  return (
    <div className="scrollable-container">
      <div className='home'>
        <Navbar />
        <section id='slide'>
          <Slide />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="gallery">
          <Gallery />
        </section>
        <section id="contactUs">
          <ContactUs />
        </section>
        <section id="location">
          <Location />
        </section>
      </div>
    </div>

  )
}

export default Home;